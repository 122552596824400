<template>
    <div>
        <MarcaDev />
        <Loader v-if="carregando" />
        <div id="app" v-show="autenticacao.autenticado && !carregando">
            <section class="section">
                <div class="container">
                    <div class="barra-superior">
                        <div style="display: flex; align-items: center; justify-content: space-between; gap: 2rem;">
                            <img class="amplify-image" alt="Logo" src="/logo.png" style="width: 150px; margin-left: 1.5rem" />
                            <span style="font-size: 24px; margin: auto">Indicadores</span>
                        </div>
                        <div style="margin-right: 2rem; display: flex; gap: 1rem">
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Digitação`" 
                                :onclick="indicador"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Pesquisa`" 
                                :onclick="pesquisa"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Complemento`" 
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Exportação`" 
                                :onclick="exportacao"
                                v-if="autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Documentos`" 
                                :onclick="documentos"
                                v-if="autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />
                            
                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Endereços`" 
                                :onclick="enderecos"
                                v-if="autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <Button
                                :classes="`is-blue-steel is-outlined`" 
                                :titulo="`Estatísticas`" 
                                :onclick="estatisticas"
                                v-if="autenticacao.coordenadores || autenticacao.admin"
                                style="width: 7rem; border-color: #ccc;"
                            />

                            <div class="dropdown is-right is-hoverable">
                                <div class="dropdown-trigger">
                                    <button class="button is-secondary" aria-haspopup="true" aria-controls="dropdown-menu3">
                                        <i class="gg-more-vertical-alt"></i>
                                    </button>
                                </div>
                                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                                    <div class="dropdown-content">
                                        <a class="dropdown-item" @click="sair()">
                                            Sair
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="columns janela-principal">
                        <div class="column is-3 lista-de-conversas">
                            <Button
                                :classes="`is-link is-outlined btn-small`" 
                                :icone="`user-add`" 
                                :titulo="`Novo usuário`" 
                                :onclick="visualizarUsuario"
                                style="margin: 0.5rem auto; display: flex;"
                                :parametro1="``"
                                :parametro2="true"
                            />

                            <div class="barra-superior barra-superior-filtro" style="height: 35px!important;">

                                <InputFiltro
                                    :onkeyup="listarUsuarios" 
                                    :placeholder="`Filtrar por nome`" 
                                    v-model="filtro"
                                />
                                
                            </div>
                            <div class="usuarios">

                                <Usuario 
                                    v-for="(usuario, idxUsuario) in usuarios"
                                    :key="idxUsuario" 
                                    :usuario-ativo="usuario.usuario == indiceAtivo"
                                    :nome="usuario.usuario"
                                    :status="usuario.status"
                                    @click.native="visualizarUsuario(usuario._id)"
                                />

                            </div>
                        </div>
                        <div class="column conversa-ativa">
                            <div class="usuario">
                                
                                <Loader v-if="carregandoUsuario" />

                                <DetalhesUsuario v-show="usuario != '' && !carregandoUsuario"
                                    :key="usuario._id ?? usuario.key" 
                                    :usuario="usuario"
                                    :autenticacao="autenticacao"
                                    :novo-usuario="novoUsuario"
                                    :listar-usuarios="listarUsuarios"
                                    :visualizar-usuario="visualizarUsuario"
                                    :remove-loader="removeLoader"
                                    :cancelar="cancelar"
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import Usuario from './Usuario.vue'
    import DetalhesUsuario from './DetalhesUsuario.vue'
    import { autenticar } from '../../services/auth.js'

    export default {
        name: "Usuarios",
        components: {
            Usuario,
            DetalhesUsuario
        },
        data(){
            return {
                usuarios: [
                    {
                        "usuario": "",
                        "status": "",
                        "dataAtualizacao": ""
                    }
                ],
                usuariosIdentificados: [],
                novoUsuario: false,
                usuario: '',
                indiceAtivo: sessionStorage.getItem('indice'),
                novoUsuario: false,
                novaMensagemTemplate: true,
                carregando: true,
                carregandoUsuario: false,
                filtro: '',
                autenticacao: {
                    autenticacao: false
                }
            }
        },
        async created(){
            /**
             * Define o titulo da pagina
             */
            document.title = 'Gerenciador de usuários'
            
            /**
             * Realizar a autenticação
             */
            await autenticar(this)
            
            /**
             * Verifica se esta autenticado
             */
            if(this.autenticacao.autenticado){
                /**
                 * Lista os usuarios
                 */
                await this.listarUsuarios()

                /**
                 * Se existir usuario selecionado, mostra os detalhes dele
                 */
                if(this.usuariosIdentificados[this.indiceAtivo]){
                    this.visualizarUsuario(this.usuariosIdentificados[this.indiceAtivo]._id)
                }
            }            
        },
        async mounted() {
            /**
             * Realizar a autenticação
             */
            await autenticar(this)
        },
        methods: {
            /**
             * Função para buscar os usuarios
             */
            async listarUsuarios(){
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Inicia o array dos usuario que serão identificados pelo numero
                 * 
                 * @var {array} aUsuariosIdentificados
                 */
                let aUsuariosIdentificados = []

                /**
                 * Realiza a requisição para buscar os usuarios
                 * 
                 * @var {array} aUsuarios
                 */
                let aUsuarios = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario`, oOpcoes)
                    .then(function(response) {
                        if(response.status == 401){
                            toastAlert('Sessão expirada, realize o login novamente')      

                            /**
                             * Remove os dados do usuario
                             */
                            sessionStorage.removeItem('access_token')
                            sessionStorage.removeItem('refresh_token')
                            sessionStorage.removeItem('token_type')
                            
                            setTimeout(() => {
                                /**
                                 * Redireciona para a tela de login
                                 */
                                window.location.href = '/login'
                            }, 2000);

                            return false
                        }
                        
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(error.codigo && typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao ?? 'Erro de sistema'}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }
                 
                        /**
                         * Itera os usuarios
                         */
                        response.dados.map((aUsuario) => {
                            /**
                             * Ordena os usuarios com base na data da última mensagem
                             */
                            response.dados.sort((a, b) => {
                                return new Date(b.usuario) - new Date(a.usuario)
                            })
                            
                            /**
                             * Adiciona o usuario no novo array com o indice sendo
                             * o número do telefone
                             */
                            aUsuariosIdentificados[aUsuario.usuario] = aUsuario

                            return aUsuario
                        })

                        return response.dados
                    })

                if(!aUsuarios){
                    return
                }
                
                aUsuarios = this.filtrarUsuarios(aUsuarios)
                
                /**
                 * Define os usuario no objeto do vue
                 */
                this.usuarios = aUsuarios
                this.usuariosIdentificados = aUsuariosIdentificados
                this.carregando = false
            },
            /**
             * Função para visualizar os detalhes do usuario
             * 
             * @param {int} sNome
             */
            async visualizarUsuario(sId, bNovoUsuario = false){
                if(sId == this.usuario._id){
                    return
                }

                /**
                 * Define se é um novo usuario
                 */
                this.novoUsuario = bNovoUsuario
                this.carregandoUsuario = !bNovoUsuario
                
                /**
                 * Verifica se o nome foi informado
                 */
                if(!sId || sId == ""){
                    this.usuario = {
                        usuario: "",
                        key: moment().unix()                    
                    }
                    
                    this.indiceAtivo = '' 

                    return
                }

                /**
                 * Verifica se foi realizado a troca de usuario
                 */
                if(this.indiceAtivo != sId){
                    this.indiceAtivo = sId 
                    sessionStorage.setItem('indice', sId) 
                }

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }
                
                /**
                 * Realiza a requisição para buscar os detalhes do usuario
                 * 
                 * @var {array} aUsuario
                 */
                const aUsuario = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/${sId}`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }
                        
                        return response.dados
                    })

                if(!aUsuario){
                    return
                }
                
                this.usuario = aUsuario
            },
            /**
             * Função para exibir os dados do novo usuario
             */
            addNovoUsuario(){
                this.indiceAtivo = -1
                this.nomeConversa = "Novo usuário",
                this.numeroTemplate = ''
                this.nomeTemplate = ''
                this.novoUsuario = true
                this.novaMensagemTemplate = true
            },
            /**
             * Função para filtrar os usuarios
             */
            filtrarUsuarios(aUsuarios){
                /**
                 * Verifica se existe informação no filtro
                 */
                if(this.filtro != ''){
                    /**
                     * Itera os usuario com o filter e verifica 
                     * se existe nome ou numero compativel
                     */
                    return aUsuarios.filter(oUsuario => {
                        return oUsuario.usuario.toLowerCase().includes(this.filtro.toLowerCase())
                    })
                }

                /**
                 * Retorna os usuarios caso não for realizado o filtro
                 */
                return aUsuarios
            },
            /**
             * Função para remover o loader
             */
            removeLoader(){
                this.carregandoUsuario = false
            },
            /**
             * Função para cancelar a cadastro/edição do usuario
             */
            cancelar(){
                this.usuario = ''
                this.indiceAtivo = -1
            },
            /**
             * Função para sair da aplicação
             */
            sair(){
                /**
                 * Remove os dados do usuario
                 */
                sessionStorage.removeItem('access_token')
                sessionStorage.removeItem('refresh_token')
                sessionStorage.removeItem('token_type')

                /**
                 * Redireciona para a tela de login
                 */
                window.location.href = '/login'
            },
            /**
             * Função para ir para o cadastro
             */
            indicador(){
                window.location.href = '/indicadores'
            },
            /**
             * Função para ir para os documentos
             */
            documentos(){
                window.location.href = '/documentos'
            },
            /**
             * Função para ir para as estatisticas
             */
            estatisticas(){
                window.location.href = '/estatisticas'
            },
            /**
             * Função para ir para os enderecos
             */
            enderecos(){
                window.location.href = '/enderecos'
            },
            /**
             * Função para ir para a exportacao
             */
            exportacao(){
                window.location.href = '/exportacao'
            },
            /**
             * Função para ir para a pesquisa
             */
            pesquisa(){
                window.location.href = '/pesquisa'
            },
            /**
             * Função para ir para a complemento
             */
            complemento(){
                window.location.href = '/complemento'
            }
        }
    }
</script>

<style scoped>
    html{
        overflow: hidden;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .inp-btn{
        color: #bbb
    }

    .janela-principal{
        height: 100vh;
        overflow: hidden;
    }

    .usuarios{
        height: 81vh;
        overflow-y: auto;
    }

    .barra-superior{
        margin: 0!important;
        height: 50px;
        background: #EDEDED;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
        border-top: 1px solid rgb(200, 200, 200);
        display: flex;
        justify-content: space-between;
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0.5rem 2rem;
        font-size: 0.8rem;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .lista-de-conversas{
        padding: 0;
        background:white;
    }

    .conversa-ativa{
        padding: 0;
        background:#eee;
        position: relative;
    }

    .usuario{
        height: 92vh;
        margin: 10px 0;
        overflow-y: auto;
        display: grid;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
    }
</style>